@import '@hiredigital/ui/variables';

.hero {
  padding: 140px 20px 80px;
  height: 300px;
}

.talentRowContainer {
  overflow: scroll;
  width: 100%;
}

.talentRow {
  width: 700px;
  display: flex;
  height: 30px;
  margin-bottom: 32px;
  flex-shrink: 0;
}

.talentSelector {
  margin-right: 15px;
  font-weight: $fw-2;
  font-size: $size-4;
  cursor: pointer;
  position: relative;

  &:hover {
    color: $primary-400;
  }
  &.selected {
    color: $primary-600;
    &:after {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      content: '';
      border-bottom: 3px solid $primary-600;
    }
  }
}

.cardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 20px;
  @include for-size(phone-only) {
    grid-template-columns: 1fr;
  }
}

.row {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  @include for-size(tablet-landscape-down) {
    // flex-wrap: wrap;
    flex-direction: column;
  }
}

.card {
  background: $white;
  border-radius: $card-border-radius;
  padding: 30px;
}

.resourceTitle {
  font-size: $size-6;
  line-height: $line-height-6;
  font-weight: $fw-2;
}

.resourceLabel {
  font-size: $size-2;
  line-height: $line-height-2;
  color: $secondary-600;
  font-weight: $fw-2;
}

.resourceDescription {
  margin-bottom: 20px;
}

.resourceRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 20px;
  @include for-size(phone-only) {
    grid-template-columns: 1fr;
  }
}

.link {
  font-weight: $fw-2;
  color: $primary-600;
  text-decoration: none;
  transition: transform 0.2s $cubic;
  &:after {
    padding-left: 5px;
    content: '\2192';
  }

  &:hover {
    transform: translateX(3px);
  }
}
